import * as React from 'react'
import FilterTemplate from '../FilterTemplate'
import { useFilter } from 'modules/products'

type Props = {
  title: string
  identifier: string
  noSearch?: boolean
  filterKey: 'brand' | 'color' | 'shop' | 'size' | 'wunderCategories' | string
  'data-cy-state-visible'?: string
}

export default function Filter(props: Props) {
  const filter = useFilter(props.identifier, props.filterKey)
  if (!filter.data.options.length && !filter.query) return null
  return (
    <>
      <span data-cy-state={props['data-cy-state-visible']} />
      <FilterTemplate
        title={props.title}
        query={filter.query}
        onQuery={props.noSearch ? undefined : filter.setQuery}
        options={filter.data.options.map((opt) => ({
          label: opt,
          selected: filter.data.value.includes(opt),
        }))}
        expanded={true}
        value={filter.data.value}
        onOptionClick={(opt) => filter.toggleFilterOption(opt.label)}
        onResetClick={filter.clear}
      />
    </>
  )
}
