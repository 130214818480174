// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useFilter } from 'modules/products'
import { MdClose } from 'react-icons/md'
import { FiCheck } from 'react-icons/fi'
import colorCodes from 'utils/colorCodes'
import ty from 'utils/typographie'
import GemustertSvg from 'svg/Inline/Gemustert-Icon.inline.svg'

type Props = {
  identifier: string
}

export default function ColorFilter(props: Props) {
  const filter = useFilter(props.identifier, 'color')
  const options = filter.exhaustive
    ? filter.data.options
    : Object.keys(colorCodes)

  const selectedDict = React.useMemo(() => {
    let dict = {}
    for (let tag of filter.data.value) dict[tag] = true
    return dict
  }, [filter.data.value])
  return (
    <Wrapper className="ColorFilter" data-cy-collection="ColorFilter">
      <div className="top">
        <div className="label">FARBE</div>
        {!!filter.data.value.length && (
          <div className="selected" data-cy-state="selected">
            {filter.data.value.map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
        )}
        {Boolean(filter.data.value.length) && (
          <div
            className="reset"
            data-cy-handle="clear-btn"
            data-cy-state="show-clear-btn"
            onClick={filter.clear}
          >
            <MdClose />
          </div>
        )}
      </div>
      <div className="options">
        {options
          .filter((opt) => colorCodes[opt])
          .sort((a, b) => (colorCodes[a].weight < colorCodes[b].weight ? -1 : 1))
          .map((opt) => (
            <div key={opt} className="circles" onClick={() => filter.toggleFilterOption(opt)}>
              {opt === 'gemustert'
                ? (
                  <ColorOption>
                    {!!selectedDict[opt] || <GemustertSvg/>}
                    {!!selectedDict[opt] && <div className='checked'><FiCheck /></div>}
                  </ColorOption>
                ) : (
                  <Option
                    key={opt}
                    selected={!!selectedDict[opt]}
                    color={colorCodes[opt].color}
                    background={colorCodes[opt].bg}
                    data-cy-handle="option"
                    // eslint-disable-next-line @kaminrunde/firescout/jsx-expression-needs-state
                    children={selectedDict[opt] && <FiCheck />}
                  />
                )}
              <p className="colorTitle">{opt}</p>
            </div>
          ))}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 12px 20px;
  > .top {
    display: flex;
    > .label {
      color: #262626;
      font-family: Raleway;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.4px;
      line-height: 20px;
    }

    > .selected {
      flex: 1;
      text-transform: uppercase;
      color: #555555;
      font-family: Raleway;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      > span {
        margin-left: 5px;
        white-space: nowrap;
      }
    }

    > .reset {
      line-height: 20px;
      width: 25px;
      margin-right: -10px;
      text-align: center;
      > svg {
        background: #eeeced;
        color: #262626;
        border-radius: 30px;
        padding: 3px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }

  > .options {
    margin-top: 15px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: repeat(5, 1fr);

    > .circles {
      > .colorTitle {
        text-transform: uppercase;
        text-align: center;
        ${ty([11, 0, 13], 'Raleway', 'normal')}
      }
    }
  }
`

const Option = styled.span.attrs((props) => ({
  style: {
    background: props.background,
    color: props.color,
  },
}))`
  cursor: pointer;
  margin: 0 auto;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 1px solid ${(props) => (props.selected ? '#555555' : 'lightgrey')};
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    font-size: 12px;
    margin-top: 2px;
  }
`

const ColorOption = styled.div`
  text-align: center;
  position: relative;

  > .checked {
    > svg {
      font-size: 16px;
      margin-top: 14px;
    }
  }
`