import * as React from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import Filter from './Filter'
import ColorFilter from './ColorFilter'
import PriceFilter from './PriceFilter'
import CloseButton from './CloseButton'
import ClearFiltersButton from './ClearFiltersButton'
import { adminMode } from 'config/utils'
import { useDynamicFilterDefinitions } from 'modules/products'

type Props = {
  identifier: string
  onClose: () => void
}

export default function DesktopDrawer(props: Props) {
  const dynamicFilterDefinitions = useDynamicFilterDefinitions(props.identifier)
  return (
    <Wrapper className="DesktopDrawer" data-cy-collection="DesktopDrawer">
      <div className="actions">
        <ClearFiltersButton identifier={props.identifier} />
        <button className="close" data-cy-handle="top-close-btn" onClick={props.onClose}>
          FILTER SCHLIESSEN
          <MdClose />
        </button>
      </div>

      <div className="filter-list">
        {adminMode && (
          <Filter
            data-cy-state-visible="has-admin-filter"
            title="Admin Kategorie"
            identifier={props.identifier}
            filterKey="wunderCategories"
          />
        )}
        <Filter title="GRÖSSE" filterKey="size" identifier={props.identifier} />
        <ColorFilter identifier={props.identifier} />
        <PriceFilter identifier={props.identifier} />
        <Filter title="MARKE" filterKey="brand" identifier={props.identifier} />
        <Filter title="SHOP" filterKey="shop" identifier={props.identifier} />
        {dynamicFilterDefinitions.data.map((d) => (
          <Filter
            key={d.path}
            title={d.label}
            filterKey={d.path}
            identifier={props.identifier}
          />
        ))}
      </div>

      <div className="commit">
        <div className="overflow" />
        <CloseButton
          identifier={props.identifier}
          data-cy-handle="bottom-close-btn"
          onClick={props.onClose}
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  overflow: auto;
  height: 100vh;
  > .actions {
    display: flex;
    justify-content: space-between;
    > .close {
      flex: 1;
      border: none;
      background: none;
      text-align: right;
      color: #323232;
      font-family: Raleway;
      font-size: 11px;
      letter-spacing: 2.4px;
      line-height: 25px;
      cursor: pointer;
      > svg {
        background: white;
        border-radius: 20px;
        margin-left: 8px;
        padding: 3px;
        font-size: 20px;
        margin-bottom: -6px;
      }
    }
  }

  > .filter-list {
    > * {
      margin: 20px 0;
      &:last-child {
        margin-bottom: 90px;
      }
    }
  }

  > .commit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    padding-top: 0;
    background: #eeeced;
    > .overflow {
      position: absolute;
      height: 40px;
      left: 0;
      right: 0;
      top: -40px;
      background: linear-gradient(180deg, rgba(238, 236, 237, 0), rgba(238, 236, 237, 1));
    }
  }
`
