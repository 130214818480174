// @flow
import * as React from 'react'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { FiSearch } from 'react-icons/fi'
import { FiSquare, FiCheckSquare, FiPlus, FiMinus } from 'react-icons/fi'

type OptionType = {
  label: string
  selected: boolean
  tag?: string
}
type Props = {
  title: string
  query?: string
  onQuery?: (query: string) => void
  options: OptionType[]
  expanded: boolean
  value: string[]
  onOptionClick: (opt: OptionType) => void
  onResetClick: () => void
}

export default function FilterTemplate(props: Props) {
  const handleQueryChange = (e) => props.onQuery && props.onQuery(e.target.value)
  const [big, setBig] = React.useState(false)
  const options = big ? props.options : props.options.slice(0, 6)
  return (
    <Wrapper className="FilterTemplate" data-cy-collection="Filter">
      <div className="top">
        <div className="label">{props.title}</div>
        {!!props.value.length && (
          <div className="selected" data-cy-state="selected">
            {props.value.map((val) => (
              <span key={val}>{val}</span>
            ))}
          </div>
        )}
        {Boolean(props.value.length) && (
          <div
            className="reset"
            data-cy-state="show-clear-btn"
            data-cy-handle="clear-btn"
            onClick={props.onResetClick}
          >
            <MdClose />
          </div>
        )}
      </div>

      <div className="content">
        {props.onQuery && (
          <div className="search" data-cy-state="searchable">
            <input
              placeholder="Suche..."
              value={props.query}
              onChange={handleQueryChange}
              data-cy-handle="search-input"
            />
            <FiSearch />
          </div>
        )}
        <OptionList>
          {options.map((opt) => (
            <Option
              key={opt.label}
              data-cy-handle="option"
              onClick={() => props.onOptionClick(opt)}
              selected={opt.selected}
            >
              {opt.selected ? <FiCheckSquare /> : <FiSquare />}
              {opt.label}
            </Option>
          ))}
        </OptionList>
        {props.options.length > 6 && (
          <button
            className="expand"
            data-cy-state="has-more-options"
            data-cy-handle="toggle-more-options"
            onClick={() => setBig(!big)}
          >
            {big ? <FiMinus /> : <FiPlus data-cy-state="more-options-visible" />}
            {big ? 'WENIGER' : 'MEHR'} ANZEIGEN
          </button>
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: white;
  padding: 12px 20px;

  > .top {
    display: flex;
    > .label {
      color: #262626;
      font-family: Raleway;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 2.4px;
      line-height: 20px;
    }

    > .selected {
      flex: 1;
      text-transform: uppercase;
      color: #555555;
      font-family: Raleway;
      font-size: 9px;
      letter-spacing: 2px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      > span {
        margin-left: 5px;
        white-space: nowrap;
      }
    }

    > .reset {
      line-height: 20px;
      width: 25px;
      margin-right: -10px;
      text-align: center;
      > svg {
        background: #eeeced;
        color: #262626;
        border-radius: 30px;
        padding: 3px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
  }

  > .content {
    margin-top: 12px;
    > .search {
      border-bottom: 1px solid #323232;
      display: flex;
      align-items: center;
      padding-bottom: 7.5px;
      > input {
        flex: 1;
        color: #555555;
        font-family: Raleway;
        font-size: 11px;
        letter-spacing: 1.2px;
        line-height: 20px;
        border: none;
        &:focus {
          outline: none;
        }
      }
      > svg {
        color: #323232;
        font-size: 18px;
      }
    }

    > .expand {
      margin-bottom: 8px;
      color: #993452;
      font-family: Raleway;
      background: none;
      font-size: 11px;
      letter-spacing: 2.4px;
      line-height: 20px;
      border: none;
      display: block;
      text-align: left;
      cursor: pointer;
      &:focus {
        outline: none;
      }

      > svg {
        margin-right: 10px;
        margin-left: -6px;
        margin-bottom: -1px;
      }
    }
  }
`

const OptionList = styled.ul`
  list-style: none;
  padding: 0;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin-bottom: 0;
  position: relative;
`

const Option = styled.li`
  color: ${(props) => (props.selected ? '#993351' : '#262626')};
  color: #262626;
  font-family: Raleway;
  font-size: 11px;
  letter-spacing: 1.2px;
  line-height: 20px;
  text-transform: uppercase;
  width: 50%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 11px;

  > svg {
    color: #262626;
    margin-right: 10px;
    font-size: 14px;
    margin-bottom: -3px;
  }
`
