// @flow
import * as React from 'react'
import styled from 'styled-components'
import { useFilterValues } from 'modules/products'

type Props = {
  identifier: string
}

export default function ClearFiltersButton(props: Props) {
  const filterValues = useFilterValues(props.identifier)
  if (!filterValues.canReset) return null
  return (
    <Wrapper
      className="ClearFiltersButton"
      data-cy-handle="clear-all"
      data-cy-state="can-clear-all"
      onClick={filterValues.clearFilterValues}
    >
      ALLE ZURÜCKSETZEN
    </Wrapper>
  )
}

const Wrapper = styled.button`
  background-color: #eeeced;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  color: #262626;
  font-family: Raleway;
  font-size: 11px;
  letter-spacing: 2.4px;
  line-height: 20px;
`
